.prompt-dialog .mask[data-v-22e1f912] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.prompt-dialog .main[data-v-22e1f912] {
  width: 80%;
  position: fixed;
  padding: 23px;
  background: #fff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.prompt-dialog .main .close-con[data-v-22e1f912] {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.prompt-dialog .main .close-con .icon[data-v-22e1f912] {
      font-size: 20px;
}
.prompt-dialog .main h2[data-v-22e1f912] {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 18px;
}
.prompt-dialog .main .content[data-v-22e1f912] {
    font-size: 14px;
    line-height: 24px;
    color: rgba(17, 17, 17, 0.8);
    position: relative;
}
.prompt-dialog .main footer[data-v-22e1f912] {
    display: flex;
    margin: 30px auto 20px;
}
.prompt-dialog .main footer > button[data-v-22e1f912] {
      background: #3890ff;
      border: 1px solid #3890ff;
      border-radius: 20px;
      color: #fff;
      font-weight: bold;
      height: 40px;
      cursor: pointer;
}
.prompt-dialog .main footer .cancel-btn[data-v-22e1f912] {
      background: #f5f6fa;
      border: 1px solid #f5f6fa;
      color: #24252a;
}
.prompt-dialog .main .multiple-child[data-v-22e1f912] {
    justify-content: space-between;
}
.prompt-dialog .main .multiple-child > button[data-v-22e1f912] {
      width: 45%;
}
.prompt-dialog .main .only-child[data-v-22e1f912] {
    justify-content: center;
}
.prompt-dialog .main .only-child > button[data-v-22e1f912] {
      width: 60%;
}
@media screen and (min-width: 768px) {
.prompt-dialog .main[data-v-22e1f912] {
    width: 300px;
}
}
